import React from "react";
import useStateValue from "frontend/state/value";
import style from "./app-header.module.css";
import InviteButtonsTopBar from "frontend/modals/invite-buttons-topbar";
import { useBoardValue } from "frontend/state/board-state";
import { isUserPaying } from "frontend/utils/users-utils";
import { InstanceType } from "shared/datamodel/schemas/ability";
import useAbility from "frontend/hooks/use-ability";
import useFeatureValue from "frontend/hooks/use-features";
import consts from "shared/consts";
import { CheckoutSource } from "frontend/billing/checkout";
import { HiddenCursors } from "frontend/icons/hidden-cursors";
import OfflineIcon from "./offline-icon";
import { useAtomValue } from "jotai";
import { isUserDisconnectedAtom } from "state-atoms";
import SaveAsTemplate from "./save-as-template";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";

export default function AppHeaderBoard({
  title = null,
  renderUpgradeCTA,
  renderBoardUsersPresence,
  children,
  onInviteClick,
  onCreateTemplate,
  onCreateTemplateLink,
  setShowUpgradeModal,
  hideCursors,
}: {
  title: any;
  renderUpgradeCTA?: () => JSX.Element;
  renderBoardUsersPresence?: () => JSX.Element;
  children?: any;
  onInviteClick?: (title?: string) => void;
  onCreateTemplate: () => void;
  onCreateTemplateLink: () => void;
  setShowUpgradeModal: (reason: CheckoutSource | null) => void;
  hideCursors?: boolean;
}) {
  const [{ user }] = useStateValue();
  const [{ user: userValue, board }] = useBoardValue();
  const isUserDisconnected = useAtomValue(isUserDisconnectedAtom);

  const { canPerformAnyAction, canShareBoard } = useAbility();
  const shouldShowShareButton =
    user && board && (canShareBoard(user, board) || !canPerformAnyAction(user, board, InstanceType.Board));

  const shouldRenderUpgradeCTA =
    userValue &&
    !userValue.isAnonymous &&
    !isUserPaying(user?.planInfo!) &&
    user?.planInfo?.source !== "touch" &&
    renderUpgradeCTA;

  const createTemplateEnable = useFeatureValue(consts.FEATURE_NAMES.CREATE_TEMPLATE_ENABLED) === "true";
  const isSaveAsTemplateEnabled = useFeatureFlag("save-as-template-feature");

  const renderShareButton = () => {
    if (shouldShowShareButton) {
      return <InviteButtonsTopBar onInviteClick={onInviteClick} />;
    }
    return null;
  };

  function onSaveTemplateToLibrary() {
    createTemplateEnable ? onCreateTemplate() : setShowUpgradeModal({ name: "create-template", value: "save-to-lib" });
  }

  function onSaveTemplateAsLink() {
    createTemplateEnable
      ? onCreateTemplateLink()
      : setShowUpgradeModal({ name: "create-template", value: "save-to-link" });
  }

  const renderSaveAsTemplateButton = () => {
    if (board && isSaveAsTemplateEnabled) {
      return (
        <>
          <div className={style.verticalDivider} />
          <SaveAsTemplate
            onSaveTemplateToLibrary={onSaveTemplateToLibrary}
            onSaveTemplateAsLink={onSaveTemplateAsLink}
            createTemplateEnable={createTemplateEnable}
            boardDocumentId={board.documentId}
          />
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {renderSaveAsTemplateButton()}
      {title && <div>{title}</div>}
      <div className={style.right}>
        <div className={style.content}>{children}</div>
        {hideCursors && <HiddenCursors />}
        {isUserDisconnected && <OfflineIcon />}
        {renderBoardUsersPresence && renderBoardUsersPresence()}
        <div className={style.contentButtons}>
          {renderShareButton()}
          {shouldRenderUpgradeCTA && renderUpgradeCTA()}
        </div>
      </div>
    </>
  );
}
