import { NewTemplateCard } from "frontend/boards-grid/template-card";
import { Crown } from "frontend/icons/crown";
import { BoardConfig, defaultBoardConfig } from "frontend/state/board-state";
import useStateValue from "frontend/state/value";
import tracking from "frontend/tracking";
import { Arrow } from "frontend/ui-components/svg-shapes";
import React, { useCallback, useEffect, useRef, useState } from "react";
import consts from "shared/consts";
import { useDebounce } from "usehooks-ts";
import TemplatePreview from "./template-preview";
import style from "./templates-modal.module.css";
import useCanvasTemplates from "../hooks/use-canvas-templates";
import {
  BlankCanvasId,
  CanvasTagId,
  CanvasTemplateMinimal,
  CanvasTemplateTag,
  TemplatePermission,
} from "shared/datamodel/schemas/canvas-template";
import classNames from "classnames";
import Skeleton from "react-loading-skeleton";
import useModalDeepLink from "frontend/hooks/use-modal-deep-link";
import { RESET } from "jotai/utils";
import { openMondayIntegrationAuthorization, useBoardIntegrations } from "frontend/hooks/use-board-integrations";
import { ModalType } from "frontend/modals/modal-manager";
import { SELECTED_CATEGORY } from "./templates-wrapper";
import SmartTemplateModal from "./smart-template-modal";
import useFeatureValue from "frontend/hooks/use-features";
import StyledButton from "frontend/ui-components/styled-button";
import Modal from "frontend/modal/modal";
import EditTemplateModal from "./edit-template-modal";
import DeleteTemplateModal from "./delete-template-modal";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import { getPathPrefix } from "../utils/getPathPrefix";
import { cleanAccountName } from "shared/util/utils";
import { useFeatureFlag } from "frontend/hooks/use-feature-flag/use-feature-flag";

type CoverType = "emptyState" | "upgradeScreen";

export default function TemplatesModal({
  onDismiss,
  onChooseTemplate,
  showUpgradeCTA,
  onUpgradeCTAClick,
  boardConfig = defaultBoardConfig,
  currentCanvasDocumentId,
  onCreateTemplate,
}: {
  onDismiss: () => void;
  onChooseTemplate: (template: CanvasTemplateMinimal) => void;
  showUpgradeCTA?: boolean;
  onUpgradeCTAClick: (source: string) => void;
  boardConfig?: BoardConfig;
  currentCanvasDocumentId?: string;
  onCreateTemplate?: () => void;
}) {
  const [{ user }] = useStateValue();
  const [previewTemplate, setPreviewTemplate] = useState<CanvasTemplateMinimal | null>(null);
  const [smartTemplate, setSmartTemplate] = useState<CanvasTemplateMinimal | null>(null);
  const [disableOutsideDetection, setDisableOutsideDetection] = useState(false);
  const [editTemplate, setEditTemplate] = useState<CanvasTemplateMinimal | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTag, setSelectedTag] = useState<{ id: CanvasTagId; name: string }>({
    id: consts.ALL_TEMPLATES_TAG_ID,
    name: "All Templates",
  });
  const [deleteTemplateId, setDeleteTemplateId] = useState<string | null>(null);
  const [tooltip, setTooltip] = useState<any>(null);
  const debouncedSearchTerm = useDebounce(searchTerm, 200);

  const { sections, tags } = useTemplates({ searchTerm: debouncedSearchTerm, selectedTag: selectedTag.id });
  const { setModalDeepLink, nameFromUrl } = useModalDeepLink();

  const { shouldAddMondayIntegration } = useBoardIntegrations();

  const modalRef = useRef(null);
  const accountNameRef = useRef<any>(null);

  const isNewBoard = !currentCanvasDocumentId;

  const createTemplateEnable = useFeatureValue(consts.FEATURE_NAMES.CREATE_TEMPLATE_ENABLED) === "true";
  const isSaveAsTemplateEnabled = useFeatureFlag("save-as-template-feature");

  const onDismissWrapper = useCallback(() => {
    setModalDeepLink({ modalType: RESET });
    onDismiss();
  }, [onDismiss]);

  // //We need to disable this detection while a user sees the upgrade modal,
  // //otherwise it closes the window when a user tries to click on upgrade plan while upgrading to use a paid template
  // useOutsideRef(modalRef, onDismissWrapper, {
  //   disableDetection: disableOutsideDetection,
  //   preventScroll: false,
  // });

  useEffect(() => {
    tracking.trackEvent(consts.TRACKING_CATEGORY.TEMPLATES, "templates-modal-shown");
  }, []);

  useEffect(() => {
    const setSelectedTagByDeepLink = () => {
      if (nameFromUrl) {
        const nameWithoutPrefix = nameFromUrl.replace(SELECTED_CATEGORY, "").trimStart();
        const tag = tags.find((tag) => tag.name.toLowerCase() === nameWithoutPrefix);
        tag?.id && setSelectedTag({ id: tag.id, name: tag.name });
      }
    };
    setSelectedTagByDeepLink();
  }, [tags?.length]);

  const chooseTemplate = (template: CanvasTemplateMinimal) => {
    tracking.trackEvent(
      consts.TRACKING_CATEGORY.CANVAS_ACTION,
      "new_template_selected_from_modal",
      template.id,
      user?.account?.id,
      user?.id
    );
    if (!isNewBoard && template.metadata?.mondaySolutionId && shouldAddMondayIntegration) {
      openMondayIntegrationAuthorization();
    } else if (template.alias === "timeline" && !isNewBoard) {
      // TODO: support timeline on new boards as well
      // currently we don't support timeline on new boards because we first need to create a canvas
      // and them connect it to an integration, but now the order is reversed
      // so it needs a refactor
      setSmartTemplate(template);
    } else {
      onChooseTemplate(structuredClone(template));
    }
  };

  const onPreviewTemplate = (template: CanvasTemplateMinimal) => {
    setPreviewTemplate(template);
    setModalDeepLink({ modalType: ModalType.TemplateLibrary, id: template.id, name: template.name });
  };

  function onTemplateClick(template: CanvasTemplateMinimal) {
    if (template.upgradeRequired) {
      onPreviewTemplate(template);
    } else {
      chooseTemplate(template);
    }
  }

  function onSearch(searchTerm: string) {
    setSelectedTag({ id: consts.ALL_TEMPLATES_TAG_ID, name: "All Templates" });
    setSearchTerm(searchTerm);
  }

  function onSeeAllClick(tagId: number, tagName: string) {
    tracking.trackEvent(consts.TRACKING_CATEGORY.TEMPLATES, "templates_modal_tag_see_all_clicked", tagId);
    setSelectedTag({ id: tagId, name: tagName });
    setSearchTerm("");
  }

  function onTagClicked(tagId: CanvasTagId, tagName: string) {
    tracking.trackEvent(consts.TRACKING_CATEGORY.TEMPLATES, "templates_modal_tag_clicked", tagId);
    setSelectedTag({ id: tagId, name: tagName });
    setSearchTerm("");
    tagId !== consts.ALL_TEMPLATES_TAG_ID
      ? setModalDeepLink({ modalType: ModalType.TemplateLibrary, variant: SELECTED_CATEGORY, name: tagName })
      : setModalDeepLink({ modalType: ModalType.TemplateLibrary });
  }

  function renderPreviewTemplate(template: CanvasTemplateMinimal) {
    return (
      <div className={style.previewContainer}>
        <div
          className={style.closePreview}
          onClick={() => {
            setPreviewTemplate(null);
            selectedTag.id !== consts.ALL_TEMPLATES_TAG_ID
              ? setModalDeepLink({
                  modalType: ModalType.TemplateLibrary,
                  variant: SELECTED_CATEGORY,
                  name: selectedTag.name,
                })
              : setModalDeepLink({ modalType: ModalType.TemplateLibrary });
          }}
        >
          <Arrow direction="left" stroke={"#113357"} scale={0.8} />
          <span>Back to templates</span>
        </div>
        <TemplatePreview
          template={template}
          onChooseTemplate={chooseTemplate}
          onUpgradeModalShown={() => {
            setModalDeepLink({ modalType: RESET });
            setDisableOutsideDetection(true);
          }}
          onUpgradeModalHidden={() => setDisableOutsideDetection(false)}
          boardConfig={boardConfig}
          onDismiss={onDismissWrapper}
        />
      </div>
    );
  }

  function renderSmartTemplate(template: CanvasTemplateMinimal) {
    if (!currentCanvasDocumentId) {
      return null;
    }
    return (
      <div className={style.previewContainer}>
        <div className={style.closePreview} onClick={() => setSmartTemplate(null)}>
          <Arrow direction="left" stroke={"#113357"} scale={0.8} />
          <span>Back</span>
        </div>
        <SmartTemplateModal
          template={template}
          handleCreatedTemplate={(template) => {
            onChooseTemplate(structuredClone(template));
          }}
          documentId={currentCanvasDocumentId}
        />
      </div>
    );
  }

  function renderCloseIcon() {
    return (
      <svg
        className={style.closeIcon}
        onClick={onDismissWrapper}
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        data-testid="close-template-modal"
      >
        <path
          d="M6.36407 6.36396L19.092 19.0919M19.092 6.36396L6.36407 19.0919"
          stroke="currentColor"
          strokeWidth="1.74"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  function renderNoSearchResults() {
    return (
      <div className={style.noSearchResults}>
        <img src={getPathPrefix("/images/no-search-results-glass.png")}/>
        <span className={style.noSearchResultsText}>
          Couldn't find templates for <b>{searchTerm}</b>
        </span>
      </div>
    );
  }

  function tagStyle(id: CanvasTagId) {
    return classNames(style.tag, {
      [style.selected]: id === selectedTag.id,
    });
  }

  function renderSectionTemplates() {
    if (searchTerm && sections.length === 0) {
      return renderNoSearchResults();
    }

    if (sections.length === 0) {
      return (
        <Skeleton count={3} containerClassName={style.templateSkeletonContainer} className={style.templateSkeleton} />
      );
    }

    return sections.map((section) => {
      const { id, title, templates, showSeeAll = false } = section;
      let spacer = null;
      if (templates.length % 3 > 0) {
        spacer = <div className={style.templateSpacer} />;
      }
      return (
        <>
          <div key={title} className={style.templatesSection}>
            {!searchTerm && (
              <div className={style.sectionHeader}>
                <span className={style.sectionTitle}>{title}</span>
                {showSeeAll && (
                  <span className={style.seeAll} onClick={() => onSeeAllClick(id, "See all")}>
                    See all
                  </span>
                )}
              </div>
            )}
            <div className={style.templates}>
              {templates.map((template, index) => (
                <NewTemplateCard
                  key={index}
                  template={template}
                  previewAvailable={template.id !== BlankCanvasId}
                  onClick={onTemplateClick}
                  onPreview={(template) => onPreviewTemplate(template)}
                  onEditTemplate={(template) => {
                    setEditTemplate(template);
                  }}
                  onDeleteTemplate={() => {
                    setDeleteTemplateId(template.id);
                  }}
                />
              ))}
              {spacer}
            </div>
          </div>
        </>
      );
    });
  }

  function renderTagList() {
    if (tags.length === 0) {
      return <Skeleton count={12} className={style.tagsSkeleton} />;
    }
    return tags.map((tag: CanvasTemplateTag) => (
      <div key={tag.id} className={tagStyle(tag.id!)} onClick={() => onTagClicked(tag.id!, tag.name)}>
        {tag.name}
      </div>
    ));
  }

  function getCoverTypeToShow() {
    if (
      !createTemplateEnable &&
      [consts.ACCOUNT_TEMPLATES_TAG_ID, consts.MY_TEMPLATES_TAG_ID].includes(Number(selectedTag.id))
    ) {
      return "upgradeScreen";
    } else {
      const myTemplates = sections.find((section) => section.id === consts.MY_TEMPLATES_TAG_ID);
      const accountTemplates = sections.find((section) => section.id === consts.ACCOUNT_TEMPLATES_TAG_ID);
      if (myTemplates && myTemplates.templates.length === 0 && selectedTag.id === consts.MY_TEMPLATES_TAG_ID) {
        return "emptyState";
      }
      if (
        accountTemplates &&
        accountTemplates.templates.length === 0 &&
        selectedTag.id === consts.ACCOUNT_TEMPLATES_TAG_ID
      ) {
        return "emptyState";
      }
    }
    return null;
  }

  function renderMyTemplatesAndAccountTemplatesTags() {
    return isSaveAsTemplateEnabled ? (
      <>
        <div className={style.separator} />
        <div
          className={tagStyle(consts.MY_TEMPLATES_TAG_ID)}
          onClick={() => onTagClicked(consts.MY_TEMPLATES_TAG_ID, "My Templates")}
          data-testid="my-templates-tag"
        >
          My Templates
        </div>
        <div
          className={tagStyle(consts.ACCOUNT_TEMPLATES_TAG_ID)}
          onClick={() => onTagClicked(consts.ACCOUNT_TEMPLATES_TAG_ID, `${user?.account?.name} Templates`)}
          data-testid="account-templates-tag"
        >
          {tooltip && (
            <Tooltip
              relativeTo={accountNameRef}
              label={accountNameRef.current.innerText}
              side={"bottom"}
              withArrow={false}
              customStyle={{
                position: "absolute",
                width: "fit-content",
                backgroundColor: "#EBEDF3",
                color: "#113357",
                padding: "0 12px",
                boxShadow: "0px 2px 4px 0px #00000026",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                font: "400 16px Poppins",
                whiteSpace: "nowrap",
                height: "36px",
                marginLeft: 176,
                marginTop: -10,
              }}
            />
          )}
          <span
            style={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              display: "block",
            }}
            onMouseEnter={() => {
              const isTextOverflowing =
                accountNameRef.current && accountNameRef.current.scrollWidth > accountNameRef.current.clientWidth;
              isTextOverflowing ? setTooltip(accountNameRef.current.innerText) : setTooltip(null);
            }}
            onMouseLeave={() => setTooltip(null)}
            ref={accountNameRef}
          >
            {`${cleanAccountName(user?.account?.name ?? "Account")} Templates`}
          </span>
        </div>
      </>
    ) : null
  }

  function renderGallery() {
    const coverType = getCoverTypeToShow();
    return (
      <>
        <div className={style.header}>
          <div className={style.searchContainer}>
            <input
              type="text"
              value={searchTerm}
              placeholder="Search template"
              autoFocus
              onChange={(e) => onSearch(e.target.value.toLowerCase())}
            />
          </div>
        </div>
        <div className={style.content}>
          <div className={style.tags}>
            {showUpgradeCTA && (
              <div className={style.unlockButton} onClick={() => onUpgradeCTAClick("Unlock all templates")}>
                <Crown size={14} />
                <span>Unlock all templates</span>
              </div>
            )}
            <div
              className={tagStyle(consts.ALL_TEMPLATES_TAG_ID)}
              onClick={() => onTagClicked(consts.ALL_TEMPLATES_TAG_ID, "All Templates")}
            >
              All Templates
            </div>
            {renderMyTemplatesAndAccountTemplatesTags()}
            <div className={style.separator} />
            {renderTagList()}
          </div>
          {coverType ? (
            renderCover(coverType)
          ) : (
            <div className={style.templatesContainer}>{renderSectionTemplates()}</div>
          )}
        </div>
      </>
    );
  }

  function renderCover(coverType: CoverType) {
    if (coverType === "emptyState") {
      return <div className={style.coverContainer}>{renderEmptyState()}</div>;
    } else if (coverType === "upgradeScreen") {
      return <div className={style.coverContainer}>{renderUpgradeScreen()}</div>;
    }
  }

  function renderUpgradeScreen() {
    return (
      <div className={classNames(style.cover, style.upgradeScreen, style.upgradeStateImageCover)}>
        <div>
          <p className={style.title}>{`Let's create your first template`}</p>
          <p className={style.subtitle}>
            Build a custom template. 
          </p>
          <p className={style.subtitle}>
            Eliminate repetitive tasks and ensure consistency across your team.
          </p>
        </div>
        <div data-testid="create-template-upgrade-cta">
          <StyledButton
            title="Upgrade"
            onClick={() => onUpgradeCTAClick("create-template")}
            customStyle={{ height: 40, width: 202, marginTop: 40, fontSize: 16, fontWeight: 500 }}
          />
        </div>
      </div>
    );
  }

  function renderEmptyState() {
    const type = selectedTag.id === consts.MY_TEMPLATES_TAG_ID ? "personal" : "account";
    return (
      <div className={classNames(style.cover, style.emptyState, style.emptyStateNoTemplatesImageCover)}>
        <div>
          <p className={style.title}>{`No ${type} templates yet`}</p>
          <p className={style.subtitle}>Looking to boost your productivity with custom templates? </p>
          <p className={style.subtitle}>Create your own canvas, save it, and HOP — you have your perfect template!</p>
        </div>
        {onCreateTemplate && (
          <StyledButton
            title="Create a template"
            onClick={onCreateTemplate}
            customStyle={{ margin: 16, height: 40, width: 202 }}
          />
        )}
      </div>
    );
  }

  if (smartTemplate) {
    return renderSmartTemplate(smartTemplate);
  }

  if (previewTemplate) {
    return renderPreviewTemplate(previewTemplate);
  }

  return (
    <div className={style.container} ref={modalRef}>
      {renderCloseIcon()}
      {renderGallery()}
      {deleteTemplateId && <DeleteTemplateModal onDismiss={() => setDeleteTemplateId(null)} templateId={deleteTemplateId} />}
      {editTemplate && (
        <Modal dimBackground={true} withPortal={false}>
          <EditTemplateModal onDismiss={() => setEditTemplate(null)} template={editTemplate} />
        </Modal>
      )}
    </div>
  );
}

type TemplatesSection = {
  id: number;
  title: string;
  templates: CanvasTemplateMinimal[];
  showSeeAll?: boolean;
};

function escapeRegExp(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function filterTemplatesBySearchAndTag(
  templates: CanvasTemplateMinimal[] | undefined,
  searchTerm: string,
  selectedTag: CanvasTagId
) {

  const safeSearchTerm = escapeRegExp(searchTerm);
  const re = new RegExp(safeSearchTerm, "i");
  return !templates
    ? []
    : templates.filter((template) => {
        // monday org chart can only appear when user selected synced-templates tag
        if (process.env.NEXT_ENV == "production" && process.env.MONDAY_ORGCHART_TEMPLATE_ID) {
          if (template.id.match(process.env.MONDAY_ORGCHART_TEMPLATE_ID)) {
            if (selectedTag == consts.SYNCED_TEMPLATES_ID) {
              return !searchTerm || re.test(template.name);
            } else {
              return false;
            }
          }
        }
        return !searchTerm || re.test(template.name);
      });
}

export function useTemplates({ searchTerm, selectedTag }: { searchTerm: string; selectedTag: CanvasTagId }) {
  const [{ user }] = useStateValue();
  const { templates, tags = [] } = useCanvasTemplates();
  const filteredTemplates = filterTemplatesBySearchAndTag(templates, searchTerm, selectedTag);
  const allTags = tags.filter((tag) => tag.templates.length > 0);
  const myTemplates = filteredTemplates.filter((template) => template.isOwner && template.permission === TemplatePermission.Private);
  const accountTemplates = filteredTemplates.filter((template) => template.isAccountTemplate && template.permission === TemplatePermission.Account);

  function getAllTemplatesSections(): TemplatesSection[] {
    let taglessTemplates = filteredTemplates.filter(
      (template) => !allTags.some((tag) => tag.templates.includes(template.id))
    );
    const tagSections = tags.map((tag) => {
      // BUG: this filters templates without tags
      const templates = filteredTemplates.filter((template) => tag.templates.includes(template.id));
      const exmapleTemplates = templates.filter((i) => tag.exampleTemplates?.includes(parseInt(i.id)));
      const templatesToShow = exmapleTemplates.length >= 3 ? exmapleTemplates : templates;
      const shownTemplates = searchTerm ? templates : templatesToShow.slice(0, 3);

      return {
        id: tag.id,
        title: tag.name,
        templates: shownTemplates,
        showSeeAll: templates.length > 3,
      } as TemplatesSection;
    });
    const generalTag: TemplatesSection = {
      id: -1,
      title: "General",
      templates: taglessTemplates,
      showSeeAll: taglessTemplates.length > 3,
    };
    const myTemplatesTag: TemplatesSection = {
      id: consts.MY_TEMPLATES_TAG_ID,
      title: "My Templates",
      templates: myTemplates.slice(0, 3),
      showSeeAll: myTemplates.length > 3,
    };
    const accountTemplatesTag: TemplatesSection = {
      id: consts.ACCOUNT_TEMPLATES_TAG_ID,
      title: `${cleanAccountName(user?.account?.name ?? "Account")} Templates`,
      templates: accountTemplates.slice(0, 3),
      showSeeAll: accountTemplates.length > 3,
    };
    if (user?.account?.id === "5") {
      tagSections.unshift(generalTag);
    }
    const recommendedIndex = tagSections.findIndex((section) => section.title === "Recommended");
    if (recommendedIndex !== -1) {
      tagSections.splice(recommendedIndex + 1, 0, myTemplatesTag, accountTemplatesTag);
    }
    return tagSections.filter((section) => section.templates.length > 0);
  }

  function getTagSection(): TemplatesSection[] {
    if (selectedTag === consts.ACCOUNT_TEMPLATES_TAG_ID) {
      return [
        {
          id: consts.ACCOUNT_TEMPLATES_TAG_ID,
          title: `${cleanAccountName(user?.account?.name ?? "Account")} Templates`,
          templates: accountTemplates,
          showSeeAll: false,
        } as TemplatesSection,
      ];
    }
    if (selectedTag === consts.MY_TEMPLATES_TAG_ID) {
      return [
        {
          id: consts.MY_TEMPLATES_TAG_ID,
          title: "My Templates",
          templates: myTemplates,
          showSeeAll: false,
        } as TemplatesSection,
      ];
    }
    const tag = tags.find((tag) => tag.id === selectedTag);
    const templates = filteredTemplates.filter((template) => tag?.templates.includes(template.id));
    return [
      {
        id: tag?.id || -1,
        title: tag?.name || "",
        templates,
        showSeeAll: false,
      } as TemplatesSection,
    ];
  }

  //returns a single section with a flat list of all temaplates across sections
  function uniqueTemplatesFlattened(sections: TemplatesSection[]): TemplatesSection[] {
    //remove duplicate temaplates across different sections
    const uniqueTemplateIds = sections.reduce((p, c) => {
      for (const template of c.templates) {
        if (!p.includes(template.id)) {
          p.push(template.id);
        }
      }
      return p;
    }, [] as string[]);

    let addedSections: TemplatesSection[] = [];
    let addedTemplateIds: string[] = [];

    //take the first template found across all sections
    for (const uniqueTemplateId of uniqueTemplateIds) {
      for (const section of sections) {
        const { templates } = section;
        const templateIds = templates.map((i) => i.id);
        if (templateIds.includes(uniqueTemplateId)) {
          const sectionAdded = addedSections.find((i) => i.id === section.id);
          if (!sectionAdded) {
            //if section wasn't added yet, make sure to remove the templates we alerady found in other sections if any, and add it
            section.templates = section.templates.filter((i) => !addedTemplateIds.includes(i.id));
            addedSections.push(section);
            addedTemplateIds = [...addedTemplateIds, ...section.templates.map((i) => i.id)];
          }
          break;
        }
      }
    }

    //since we are returnning a flattened list of templates, randomly take the first section and add all the templates to it
    const res = [{ ...addedSections[0], templates: addedSections.flatMap((i) => i.templates) }];

    return res;
  }

  const sectionsToShow = selectedTag === consts.ALL_TEMPLATES_TAG_ID ? getAllTemplatesSections() : getTagSection();

  return {
    sections: searchTerm ? uniqueTemplatesFlattened(sectionsToShow) : sectionsToShow, //in search we are flattening the templates across all sections, so we want to make sure we don't have dupliacted templates across sections
    tags: allTags,
  };
}
