import { DOMPlacement } from "frontend/ui-components/floaters/position-utils";
import StyledButton, { ButtonSize } from "frontend/ui-components/styled-button";
import { CSSProperties } from "react";
import { WalkthroughTipModel } from "./walkthrough-tip-model";
import style from "./walkthrough-tip.module.css";

export default function WalkthroughTip({
  tip,
  onDismiss,
  placement,
  showSkipButton = true,
  headerTitle,
}: {
  tip: Omit<WalkthroughTipModel, "id">;
  onDismiss: (skip: boolean) => void;
  placement?: DOMPlacement;
  showSkipButton?: boolean;
  headerTitle?: string;
}) {
  const { title, text, image, buttonTitle = "Got it" } = tip;

  const arrowSize = 15;

  function arrowPosition(placement: DOMPlacement) {
    let { side = "bottom", shift = 0 } = placement;
    let top, left, right, bottom, backgroundColor;
    let transform;
    switch (side) {
      case "top":
        bottom = 0;
        left = `max(min(calc( 50% - ${shift}px ), calc( 100% - ${arrowSize}px )), ${arrowSize}px)`;
        transform = "translate(-50%, 50%) rotate(-45deg)";
        backgroundColor = "#113357";
        break;
      case "bottom":
        top = 0;
        left = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
        transform = "translate(-50%, -50%) rotate(135deg)";
        backgroundColor = "#fff";
        break;
      case "left":
        top = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
        right = 0;
        transform = "translate(-50%, -50%) rotate(225deg)";
        if (shift < arrowSize / 2) {
          backgroundColor = "#113357";
        } else {
          backgroundColor = "#fff";
        }
        break;
      case "right":
        top = `min(max(calc( 50% - ${shift}px ), ${arrowSize}px), calc( 100% - ${arrowSize}px ))`;
        left = 0;
        transform = "translate(-50%, -50%) rotate(45deg)";
        if (shift < arrowSize / 2) {
          backgroundColor = "#113357";
        } else {
          backgroundColor = "#fff";
        }
        break;
      default:
        const _unhandled: never = side;
    }
    return {
      divStyle: {
        position: "absolute",
        top,
        left,
        right,
        bottom,
        transform,
      } as CSSProperties,
      svgStyle: {
        width: arrowSize,
        height: arrowSize,
        viewBox: `0 0 ${arrowSize} ${arrowSize}`,
        fill: backgroundColor,
      },
    };
  }

  let arrowSvgStyle, arrowDivStyle;
  if (placement) {
    const { divStyle, svgStyle } = arrowPosition(placement);
    arrowSvgStyle = svgStyle;
    arrowDivStyle = divStyle;
  }

  return (
    <div className={style.container}>
      {placement && (
        <div className={style.arrow} style={arrowDivStyle}>
          <svg {...arrowSvgStyle}>
            <polygon points={`0,0 0,15 15,15`} />
          </svg>
        </div>
      )}
      <div className={style.image} style={{ backgroundImage: `url(${image})` }} />
      <div className={style.content}>
        {headerTitle && <div className={style.miniTitle}>{headerTitle}</div>}
        <div className={style.title}>{title}</div>
        <div className={style.description}>{text}</div>
        <div className={style.buttonContainer}>
          {showSkipButton ? (
            <span className={style.skipButton} onClick={() => onDismiss(true)}>
              Skip tutorial
            </span>
          ) : (
            <p />
          )}
          <StyledButton title={buttonTitle} size={ButtonSize.small} onClick={() => onDismiss(false)} />
        </div>
      </div>
    </div>
  );
}
