import { markTipSeen as markTipSeenApi } from "frontend/api";
import { atom, useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { TipName } from "shared/datamodel/schemas";
import { userTipsAtom } from "state-atoms";
import { WalkthroughTipName, walkthroughTipsOrder } from "./walkthrough-tip-model";

const queuedTipsAtom = atom<WalkthroughTipName[]>([]);

export function useNextWalkthroughTip() {
  const [userTips, setUserTips] = useAtom(userTipsAtom);
  const [queuedTips, setQueuedTips] = useAtom(queuedTipsAtom);

  const finishedWalkthrough = userTips.has(TipName.canvasWalkthrough);

  // filter out tips that have already been seen and sort by priority
  const unseenWalkthroughTips = queuedTips.filter((tip) => userTips && !userTips.has(tip));
  unseenWalkthroughTips.sort((a, b) => {
    const aIndex = walkthroughTipsOrder[a];
    const bIndex = walkthroughTipsOrder[b];
    return aIndex - bIndex; // sort in descending order (lower index = higher priority)
  });

  // get the first unseen tip
  const nextTip = unseenWalkthroughTips.length > 0 && !finishedWalkthrough ? unseenWalkthroughTips[0] : null;

  function markTipSeen(tipName: TipName) {
    markTipSeenApi(tipName);
    if (unseenWalkthroughTips.length == 1) {
      markTipSeenApi(TipName.canvasWalkthrough);
    }
    setUserTips((tips) => new Set([...tips, tipName]));
  }

  function queueTip(tipName: WalkthroughTipName) {
    if (queuedTips.includes(tipName)) return; // don't queue tips twice
    setQueuedTips((tips) => [...tips, tipName]);
  }

  return { nextTip, markTipSeen, queueTip };
}

export default function useWalkthroughTip(tipName: WalkthroughTipName) {
  const { nextTip, markTipSeen, queueTip } = useNextWalkthroughTip();

  useEffect(() => {
    queueTip(tipName);
  }, []);

  // show the tip if it's the next tip to be shown
  const showTip = nextTip === tipName;

  return { showTip, markTipSeen };
}
