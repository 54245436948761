import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { createCanvasTemplate, getTemplates, getTemplatesAdmin, getTemplatesTags } from "../api";
import { CanvasTemplateForm, CanvasTemplateMinimal, CanvasTemplateTag } from "shared/datamodel/schemas/canvas-template";
import LoadedState from "../state/loadedState";

interface TemplatesState {
  templates?: CanvasTemplateMinimal[];
  tags?: CanvasTemplateTag[];
  loadingState: LoadedState;
}

const templateGlobalState = atom<TemplatesState>({ loadingState: LoadedState.initial });

function normalizeTagData(tag: { id: number; name: string; templates: number[]; example_templates: number[] }) {
  return {
    ...tag,
    templates: tag.templates.map(String),
    exampleTemplates: tag.example_templates?.slice(),
  };
}

export default function useCanvasTemplates(isAdmin = false) {
  const [state, setState] = useAtom(templateGlobalState)

  async function fetchTemplates(force: boolean) {
    if (state.loadingState == LoadedState.loading) return; // Prevent multiple concurrent calls
    if (!force && state.loadingState == LoadedState.finished) return; // Prevent multiple calls (unless forced)
    setState({ loadingState: LoadedState.loading });
    try {
      const [templates, tags] = await Promise.all([
        isAdmin ? getTemplatesAdmin() : getTemplates(),
        getTemplatesTags().then((tags) => tags.map(normalizeTagData)),
      ]);
      setState({ templates, tags, loadingState: LoadedState.finished });
    } catch (e) {
      setState({ loadingState: LoadedState.failed });
    }
  }

  useEffect(() => { fetchTemplates(false) }, []);
  const forceReload = () => fetchTemplates(true);

  async function createTemplate(template: CanvasTemplateForm) {
    const newTemplate = await createCanvasTemplate(template);
    const newTemplates = state.templates ? [...state.templates, newTemplate] : [newTemplate];
    setState((state) => ({ ...state, templates: newTemplates }));
    return newTemplate;
  }

  function editTemplate(template: CanvasTemplateMinimal) {
    const newTemplates = state.templates?.map((t) => (t.id === template.id ? template : t));
    setState((state) => ({ ...state, templates: newTemplates}));
  }

  function deleteTemplate(templateId: string) {
    const newTemplates = state.templates?.filter((t) => t.id !== templateId);
    setState((state) => ({...state,  templates: newTemplates }));
  }

  return { ...state, forceReload, createTemplate, editTemplate, deleteTemplate };
}
